import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  FILE_APPEND_REQUEST,
  FILE_APPEND_SUCCESS,
  CHANGE_UPLOAD_PROGRESS,
  ABORT_UPLOADING_REQUEST,
  SET_CANCEL_REQUEST,
  REMOVE_UPLOAD_REQUEST,
  FILE_APPEND_ERROR,
  GENERATE_UPLOAD_URL_REQUEST,
  GENERATE_UPLOAD_URL_IS_START,
  GENERATE_UPLOAD_URL_SUCCESS,
  GENERATE_UPLOAD_URL_ERROR,
  SEND_TRANSCODE_REQUEST,
  SEND_TRANSCODE_SUCCESS,
  SEND_TRANSCODE_ERROR,
  TRANSCODE_URL_SUCCESS,
  TRANSCODE_URL_REQUEST,
  TRANSCODE_URL_ERROR,
  SEND_TRANSCODE_URL_REQUEST,
  SET_CURRENT_CHUNK,
  SET_UPLOADING_FILES,
  SET_UPLOADING_FILES_SUCCESS,
  GENERATE_UPLOAD_URL_IS_ASSET_START,
  GENERATE_UPLOAD_URL_ASSET_SUCCESS,
  ABORT_UPLOADING_ASSET_REQUEST,
  REMOVE_UPLOAD_ASSET_REQUEST,
  CHANGE_UPLOAD_ASSET_PROGRESS,
  CLEAR_ASSET_FILES,
  SET_CANCEL_ASSET_REQUEST,
} from '../../actions/UploadingActions/upload';
import CONFIG from '../../../constants/config';
import {
  ABORT_UPLOADING_REPLACE_REQUEST,
  CHANGE_UPLOAD_REPLACE_PROGRESS,
  FILE_APPEND_REPLACE_ERROR,
  FILE_APPEND_REPLACE_REQUEST,
  FILE_APPEND_REPLACE_SUCCESS,
  GENERATE_UPLOAD_URL_REPLACE_ERROR,
  GENERATE_UPLOAD_URL_REPLACE_IS_START,
  GENERATE_UPLOAD_URL_REPLACE_REQUEST,
  GENERATE_UPLOAD_URL_REPLACE_SUCCESS,
  REMOVE_UPLOAD_REPLACE_REQUEST,
  REPLACE_URL_ERROR,
  REPLACE_URL_REQUEST,
  REPLACE_URL_SUCCESS,
  REVERT_PLACE_ERROR,
  REVERT_PLACE_REQUEST,
  REVERT_PLACE_SUCCESS,
  SEND_TRANSCODE_REPLACE_ERROR,
  SEND_TRANSCODE_REPLACE_REQUEST,
  SET_CANCEL_REPLACE_REQUEST,
  SET_CURRENT_CHUNK_REPLACE,
} from '../../actions/replaceActions/replace';
import { GET_MEDIA_ITEM_SUCCESS } from '../../actions/media/mediaItem';

const state = {
  files: [],
  assetFiles: [],
  currentChunk: null,
  currentChunkReplace: null,
  message: '',
  isUploading: false,
  isReplace: false,
  isLoading: false,
  allUploadingFiles: [],
};

const actions = {
  [FILE_APPEND_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    const data = params.file;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const url = params.uploadUrl;
    const payload = {
      name: data.name,
      source,
      id: params.id,
      cancelToken: source.token,
    };

    if (!params.isAsset) {
      commit(SET_CANCEL_REQUEST, payload);
    } else {
      commit(SET_CANCEL_ASSET_REQUEST, payload);
    }

    commit(SET_CURRENT_CHUNK, payload);

    apiCall.put(
      url,
      data,
      {
        headers: {
          'Tus-Resumable': '1.0.0',
          'Content-Type': data.type,
          'Upload-Offset': 0,
        },
        cancelToken: source.token,
        onUploadProgress(progressEvent) {
          const progressPayload = {
            progressEvent,
            name: data.name,
            id: params.id,
            numberofChunks: params.numberofChunks,
            chunkCounter: params.chunkCounter,
            totalSize: params.totalSize,
          };
          if (!params.isAsset) {
            commit(CHANGE_UPLOAD_PROGRESS, progressPayload);
          } else {
            commit(CHANGE_UPLOAD_ASSET_PROGRESS, progressPayload);
          }
        },
      },
    )
      .then(() => {
        commit(FILE_APPEND_SUCCESS);
        resolve(params);
      })
      .catch((err) => {
        console.log(err, 'err');
        commit(FILE_APPEND_ERROR);
        reject(err.response);
      });
  }),
  [GENERATE_UPLOAD_URL_REQUEST]:
  ({ commit }, {
    accountId, fileData, isAsset = false, fileType,
  }) => new Promise((resolve, reject) => {
    const {
      file,
      folder,
    } = fileData;
    const {
      errorTypeSize,
      errorTypeFormat,
    } = CONFIG.errorMessages.upload;
    const isAudio = file.type.substring(0, 5) === 'audio' && file.size;

    if (!isAsset) {
      if ((file.type.substring(0, 5) === 'video' && file.size)
        || isAudio
        || (file.name.substr(file.name.length - 4) === '.mkv')) {
        commit(GENERATE_UPLOAD_URL_IS_START, file);
      } else if (!file.size) {
        reject(errorTypeSize);
      } else {
        reject(errorTypeFormat);
        return;
      }
    } else {
      commit(GENERATE_UPLOAD_URL_IS_ASSET_START, file);
    }

    const data = {
      filename: file.name,
      length: file.size,
      mediaFolder: folder,
    };

    if (isAsset) {
      delete data.mediaFolder;
      delete data.length;
      data.fileLength = file.size;

      if (fileType) {
        data.fileType = fileType;
      }
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    const payload = {
      source,
      name: file.name,
    };
    commit(SET_CANCEL_REQUEST, payload);

    let url = `${accountId}/media-collection/item/generate-upload-url`;
    if (isAsset) {
      url = `${accountId}/upload-file/generate-upload-url`;
    }

    apiCall.post(url,
      data,
      {
        cancelToken: source.token,
      })
      .then((resp) => {
        const params = {
          file,
          id: resp.data.id,
        };

        if (isAsset) {
          params.uploadUrl = resp.data.uploadPresignedUrls;
        } else {
          params.uploadUrl = resp.data.config.uploadPresignedUrls;
        }

        if (!isAsset) {
          commit(GENERATE_UPLOAD_URL_SUCCESS, params);
        } else {
          commit(GENERATE_UPLOAD_URL_ASSET_SUCCESS, params);
        }

        resolve(params);
      })
      .catch((err) => {
        commit(GENERATE_UPLOAD_URL_ERROR);
        reject(err.response);
      });
  }),
  [SEND_TRANSCODE_REQUEST]:
  ({ commit }, { accountId, itemId, isAsset = false }) => new Promise((resolve, reject) => {
    let url = `${accountId}/media-collection/item/${itemId}/complete-uploading`;
    if (isAsset) {
      url = `${accountId}/upload-file/complete-uploading/${itemId}`;
    }

    apiCall.put(url)
      .then(() => {
        commit(SEND_TRANSCODE_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(SEND_TRANSCODE_ERROR);
        reject(err.response);
      });
  }),
  [SEND_TRANSCODE_URL_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(TRANSCODE_URL_REQUEST);

    apiCall.post(`${accountId}/media-collection/item/url`, data)
      .then(() => {
        commit(TRANSCODE_URL_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(TRANSCODE_URL_ERROR);
        reject(err.response);
      });
  }),
  [ABORT_UPLOADING_REQUEST]: ({ commit, dispatch }, id) => {
    commit(ABORT_UPLOADING_REQUEST, id);

    const upload = state.files.find((file) => file.id === id);
    upload.cancelToken.cancel();
    const uploadChunk = state.currentChunk.id === upload.id;
    if (uploadChunk) {
      state.currentChunk.source.cancel();
    }

    if (state.isReplace) {
      dispatch(REVERT_PLACE_REQUEST, id);
    }
  },
  [ABORT_UPLOADING_ASSET_REQUEST]: ({ commit }, id) => {
    commit(ABORT_UPLOADING_ASSET_REQUEST, id);

    const upload = state.assetFiles.find((file) => file.id === id);
    upload.cancelToken.cancel();
    const uploadChunk = state.currentChunk.id === upload.id;
    if (uploadChunk) {
      state.currentChunk.source.cancel();
    }
  },
  [REMOVE_UPLOAD_REQUEST]: ({ commit }, id) => {
    commit(REMOVE_UPLOAD_REQUEST, id);
  },
  [REMOVE_UPLOAD_ASSET_REQUEST]: ({ commit }, id) => {
    commit(REMOVE_UPLOAD_ASSET_REQUEST, id);
  },
  [CLEAR_ASSET_FILES]: ({ commit }) => {
    commit(CLEAR_ASSET_FILES);
  },
  [SET_UPLOADING_FILES]: ({ commit }, files) => {
    commit(SET_UPLOADING_FILES_SUCCESS, files);
  },
  [FILE_APPEND_REPLACE_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    const data = params.file;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const url = params.uploadUrl;
    const payload = {
      name: data.name,
      source,
      id: params.id,
      cancelToken: source.token,
    };
    commit(SET_CANCEL_REPLACE_REQUEST, payload);
    commit(SET_CURRENT_CHUNK_REPLACE, payload);

    apiCall.put(
      url,
      data,
      {
        headers: {
          'Tus-Resumable': '1.0.0',
          'Content-Type': data.type,
          'Upload-Offset': 0,
        },
        cancelToken: source.token,
        onUploadProgress(progressEvent) {
          const progressPayload = {
            progressEvent,
            name: data.name,
            id: params.id,
            numberofChunks: params.numberofChunks,
            chunkCounter: params.chunkCounter,
            totalSize: params.totalSize,
          };
          commit(CHANGE_UPLOAD_REPLACE_PROGRESS, progressPayload);
        },
      },
    )
      .then(() => {
        commit(FILE_APPEND_REPLACE_SUCCESS);
        resolve(params);
      })
      .catch((err) => {
        commit(FILE_APPEND_REPLACE_ERROR);
        reject(err.response);
      });
  }),
  [GENERATE_UPLOAD_URL_REPLACE_REQUEST]:
  ({ commit }, { accountId, itemId, file }) => new Promise(
    (resolve, reject) => {
      const {
        errorTypeSize,
        errorTypeFormat,
      } = CONFIG.errorMessages.upload;
      const isAudio = file.type.substring(0, 5) === 'audio' && file.size;
      if ((file.type.substring(0, 5) === 'video' && file.size)
        || isAudio
        || (file.name.substr(file.name.length - 4) === '.mkv')) {
        commit(GENERATE_UPLOAD_URL_IS_START, file);
      } else if (!file.size) {
        reject(errorTypeSize);
      } else {
        reject(errorTypeFormat);
        return;
      }

      const data = {
        filename: file.name,
        length: file.size,
      };

      const { CancelToken } = axios;
      const source = CancelToken.source();
      apiCall.put(`${accountId}/media-collection/item/generate-replace-url/${itemId}`,
        data,
        {
          cancelToken: source.token,
        })
        .then((resp) => {
          const params = {
            file,
            uploadUrl: resp.data.config.uploadPresignedUrls,
            id: resp.data.id,
          };
          commit(GENERATE_UPLOAD_URL_REPLACE_SUCCESS, params);
          commit(GET_MEDIA_ITEM_SUCCESS, resp.data);
          resolve(params);
        })
        .catch((err) => {
          commit(GENERATE_UPLOAD_URL_REPLACE_ERROR);
          reject(err.response);
        });
    },
  ),
  [SEND_TRANSCODE_REPLACE_REQUEST]:
  ({ commit }, { accountId, itemId }) => new Promise((resolve, reject) => {
    apiCall.put(`${accountId}/media-collection/item/${itemId}/complete-uploading`)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        commit(SEND_TRANSCODE_REPLACE_ERROR);
        reject(err.response);
      });
  }),
  [ABORT_UPLOADING_REPLACE_REQUEST]: ({ commit }, id) => new Promise(() => {
    commit(ABORT_UPLOADING_REPLACE_REQUEST, id);

    const upload = state.files.find((file) => file.id === id);
    upload.cancelToken.cancel();
    const uploadChunk = state.currentChunkReplace.id === upload.id;
    if (uploadChunk) {
      state.currentChunkReplace.source.cancel();
    }
  }),
  [REMOVE_UPLOAD_REPLACE_REQUEST]: ({ commit }, id) => {
    commit(REMOVE_UPLOAD_REPLACE_REQUEST, id);
  },
  [REVERT_PLACE_REQUEST]:
  ({ commit, dispatch }, { accountId, itemId }) => new Promise((resolve, reject) => {
    apiCall.put(`${accountId}/media-collection/item/revert-replacement/${itemId}`)
      .then((resp) => {
        commit(REVERT_PLACE_SUCCESS);
        dispatch(ABORT_UPLOADING_REPLACE_REQUEST, itemId);
        commit(GET_MEDIA_ITEM_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        commit(REVERT_PLACE_ERROR);
        reject(err.response);
      });
  }),
  [REPLACE_URL_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(REPLACE_URL_REQUEST);

    const data = {
      url: params.url,
    };

    apiCall.put(`${params.accountId}/media-collection/item/replace-url/${params.mediaId}`, data)
      .then((resp) => {
        commit(REPLACE_URL_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        commit(REPLACE_URL_ERROR, err);
        reject(err);
      });
  }),
};

const mutations = {
  [GENERATE_UPLOAD_URL_IS_START]: (state, payload) => {
    state.isUploading = true;
    state.files.push({
      id: state.files.length,
      cancelToken: null,
      file: payload,
      progress: 0,
      status: '',
    });
  },
  [GENERATE_UPLOAD_URL_IS_ASSET_START]: (state, payload) => {
    state.isUploading = true;
    state.assetFiles.push({
      id: state.assetFiles.length,
      cancelToken: null,
      file: payload,
      progress: 0,
      status: '',
    });
  },
  [GENERATE_UPLOAD_URL_SUCCESS]: (state, payload) => {
    state.isUploading = true;
    state.files.map((item) => {
      if (payload.file.name === item.file.name && !item.progress) {
        item.id = payload.id;
      }

      return null;
    });
  },
  [GENERATE_UPLOAD_URL_ASSET_SUCCESS]: (state, payload) => {
    state.isUploading = true;
    state.assetFiles.map((item) => {
      if (payload.file.name === item.file.name && !item.progress) {
        item.id = payload.id;
      }

      return null;
    });
  },
  [GENERATE_UPLOAD_URL_REPLACE_ERROR]: () => {
    state.isLoading = false;
  },
  [SEND_TRANSCODE_SUCCESS]: () => {},
  [CLEAR_ASSET_FILES]: () => {
    state.assetFiles = [];
  },
  [SEND_TRANSCODE_ERROR]: () => {},
  [GENERATE_UPLOAD_URL_REPLACE_REQUEST]: () => {
    state.isLoading = true;
  },
  [TRANSCODE_URL_REQUEST]: () => {
    state.isLoading = true;
  },
  [TRANSCODE_URL_SUCCESS]: () => {
    state.isLoading = false;
  },
  [TRANSCODE_URL_ERROR]: () => {
    state.isLoading = false;
  },
  [ABORT_UPLOADING_REQUEST]: (state, payload) => {
    state.isUploading = false;
    state.files.forEach((item) => {
      if (item.id === payload) {
        item.status = 'Canceled';
      }
    });
  },
  [ABORT_UPLOADING_ASSET_REQUEST]: (state, payload) => {
    state.isUploading = false;
    state.assetFiles.forEach((item) => {
      if (item.id === payload) {
        item.status = 'Canceled';
      }
    });
  },
  [SET_CANCEL_REQUEST]: (state, payload) => {
    state.files.forEach((item) => {
      if (item.file.name === payload.name) {
        item.cancelToken = payload.source;
      }
    });
  },
  [SET_CANCEL_ASSET_REQUEST]: (state, payload) => {
    state.assetFiles.forEach((item) => {
      if (item.file.name === payload.name) {
        item.cancelToken = payload.source;
      }
    });
  },
  [REMOVE_UPLOAD_REQUEST]: (state, payload) => {
    const index = state.files.findIndex((item) => item.id === payload);
    state.files.splice(index, 1);
    state.allUploadingFiles.pop();
  },
  [REMOVE_UPLOAD_ASSET_REQUEST]: (state, payload) => {
    const index = state.assetFiles.findIndex((item) => item.id === payload);
    state.assetFiles.splice(index, 1);
    state.allUploadingFiles.pop();
  },
  [FILE_APPEND_ERROR]: (state) => {
    state.isUploading = false;
  },
  [FILE_APPEND_SUCCESS]: (state) => {
    state.files.forEach((item) => {
      if (item.progress === 100) {
        item.status = 'Uploaded';
      }
    });
  },
  [CHANGE_UPLOAD_PROGRESS]: (state, payload) => {
    const percentComplete = Number(Math.round(
      (payload.progressEvent.loaded / payload.progressEvent.total)
      * 100,
    ));
    // eslint-disable-next-line max-len
    const totalPercentComplete = Math.round((((payload.chunkCounter - 1) / payload.numberofChunks) * 100) + percentComplete / payload.numberofChunks);
    state.files.forEach((item) => {
      if (item.id === payload.id && !item.status) {
        item.progress = totalPercentComplete;
      }
    });
  },
  [CHANGE_UPLOAD_ASSET_PROGRESS]: (state, payload) => {
    const percentComplete = Number(Math.round(
      (payload.progressEvent.loaded / payload.progressEvent.total)
      * 100,
    ));
    // eslint-disable-next-line max-len
    const totalPercentComplete = Math.round((((payload.chunkCounter - 1) / payload.numberofChunks) * 100) + percentComplete / payload.numberofChunks);
    state.assetFiles.forEach((item) => {
      if (item.id === payload.id && !item.status) {
        item.progress = totalPercentComplete;
      }
    });
  },
  [GENERATE_UPLOAD_URL_REPLACE_IS_START]: (state, payload) => {
    state.isUploading = true;
    state.isLoading = true;
    state.files.push({
      id: state.files.length,
      cancelToken: null,
      file: payload,
      progress: 0,
      status: '',
    });
  },
  [GENERATE_UPLOAD_URL_REPLACE_SUCCESS]: (rootState, payload) => {
    state.isUploading = true;
    state.isReplace = true;
    state.isLoading = false;
    state.files.map((item) => {
      if (payload.file.name === item.file.name && !item.progress) {
        item.id = payload.id;
      }

      return null;
    });
  },
  [ABORT_UPLOADING_REPLACE_REQUEST]: (state, payload) => {
    state.isUploading = false;
    state.files.forEach((item) => {
      if (item.id === payload) {
        item.status = 'Canceled';
      }
    });
  },
  [SET_CANCEL_REPLACE_REQUEST]: (state, payload) => {
    state.files.forEach((item) => {
      if (item.id === payload.id) {
        item.cancelToken = payload.source;
      }
    });
  },
  [REMOVE_UPLOAD_REPLACE_REQUEST]: (state, payload) => {
    const index = state.files.findIndex((item) => item.id === payload);
    state.files.splice(index, 1);
  },
  [FILE_APPEND_REPLACE_ERROR]: (state) => {
    state.isUploading = false;
    state.isLoading = false;
  },
  [FILE_APPEND_REPLACE_SUCCESS]: (state) => {
    state.isLoading = false;
    state.files.forEach((item) => {
      if (item.progress === 100) {
        item.status = 'Uploaded';
      }
    });
  },
  [CHANGE_UPLOAD_REPLACE_PROGRESS]: (state, payload) => {
    const percentComplete = Number(Math.round(
      (payload.progressEvent.loaded / payload.progressEvent.total)
      * 100,
    ));
    // eslint-disable-next-line max-len
    const totalPercentComplete = Math.round((((payload.chunkCounter - 1) / payload.numberofChunks) * 100) + percentComplete / payload.numberofChunks);
    state.files.forEach((item) => {
      if (item.id === payload.id && !item.status) {
        item.progress = totalPercentComplete;
      }
    });
  },
  [FILE_APPEND_REPLACE_REQUEST]: () => {
    state.isLoading = true;
  },
  [SET_CURRENT_CHUNK]: (state, payload) => {
    state.currentChunk = payload;
  },
  [SET_CURRENT_CHUNK_REPLACE]: (state, payload) => {
    state.currentChunkReplace = payload;
  },
  [SET_UPLOADING_FILES_SUCCESS]: (state, payload) => {
    if (!state.allUploadingFiles.length) {
      state.allUploadingFiles = Array.from(payload);
    } else {
      state.allUploadingFiles = state.allUploadingFiles.concat(Array.from(payload));
    }
  },
  [REVERT_PLACE_SUCCESS]: () => {},
  [REVERT_PLACE_ERROR]: () => {},
  [REPLACE_URL_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [REPLACE_URL_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [REPLACE_URL_ERROR]: (state) => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
