var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-list-wrapper",style:(_vm.isInspected && {overflowY: 'inherit'})},[_c('Loader',{attrs:{"isVisible":_vm.isLoading || _vm.replaceLoading}}),_c('div',{staticClass:"media-list-container"},[_c('div',{class:[(_vm.isInspector) ? 'media-list-inspector' : 'media-list']},_vm._l((_vm.mediaList),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"item-wrapper",on:{"click":function($event){return _vm.inspectMediaItem(item.id)}}},[_c('drag',{attrs:{"transferData":item}},[((item.config.transcodingPercent !== _vm.maxPerCent || item.status === _vm.transcoding)
                && item.type === 'vod')?_c('div',{staticClass:"cont"},[(item.status !== _vm.error && item.status !== _vm.uploading)?_c('div',{staticClass:"back"},[_c('div',{staticClass:"progress",style:({width: ((item.config.transcodingPercent) + "%")})})]):_vm._e(),(item.status === _vm.transcoding || item.status === _vm.error || _vm.replacementFile)?_c('div',{class:['transcoding', item.status === _vm.error && 'failed-transcoding']},[((item.status === _vm.transcoding))?_c('div',{attrs:{"title":Math.floor(item.config.transcodingPercent) + '% Transcoding'}},[_c('span',[_vm._v(" "+_vm._s(Math.floor(item.config.transcodingPercent))+" % ")]),_c('span',[_vm._v(" Transcoding ")])]):_vm._e(),(item.status === _vm.uploading && _vm.replacementFile)?_c('span',{staticClass:"replacement-failed"},[_vm._v(" Replacement failed ")]):(item.status === _vm.error)?_c('span',[_vm._v(" Transcoding failed ")]):_vm._e()]):_vm._e()]):_vm._e(),(item.status === _vm.packaging)?_c('div',{staticClass:"transcoding packaging"},[_vm._v(" "+_vm._s(_vm.packaging)+" ")]):_vm._e(),((item.status === _vm.error && item.config.transcodingPercent === 100)
                || (item.status === _vm.error && item.type === 'audio'))?_c('div',{staticClass:"replacement-failed pack"},[_vm._v(" Packaging failed ")]):_vm._e(),(item.status === 'uploading' && item.type ==='audio')?_c('div',{staticClass:"replacement-failed pack"},[_vm._v(" Uploading ")]):_vm._e(),_c('img',{staticClass:"item-img",attrs:{"alt":item.name,"src":_vm.getPosterImage(item)}}),(item.hasProtection && _vm.getCurrentAccount.brushfireProtectionEnabled)?_c('inline-svg',{staticClass:"svg-icon brush",attrs:{"src":_vm.iconProtection}}):_vm._e(),(item.contentType === 'Movie')?_c('inline-svg',{class:['svg-icon content-icons',
                       item.hasProtection
                         && _vm.getCurrentAccount.brushfireProtectionEnabled
                         && 'hasBrush'],attrs:{"title":'Movie',"src":_vm.iconMovie}}):_vm._e(),(item.contentType === 'Event')?_c('inline-svg',{class:['svg-icon content-icons',
                       item.hasProtection
                         && _vm.getCurrentAccount.brushfireProtectionEnabled
                         && 'hasBrush'],attrs:{"title":'Event',"src":_vm.iconEvent}}):_vm._e(),(item.contentType === 'Trailer')?_c('inline-svg',{class:['svg-icon content-icons',
                       item.hasProtection
                         && _vm.getCurrentAccount.brushfireProtectionEnabled
                         && 'hasBrush'],attrs:{"title":'Trailer',"src":_vm.iconTrailer}}):_vm._e(),(item.contentType === 'Extra')?_c('inline-svg',{class:['svg-icon content-icons',
                       item.hasProtection
                         && _vm.getCurrentAccount.brushfireProtectionEnabled
                         && 'hasBrush'],attrs:{"title":'Extra',"src":_vm.iconExtra}}):_vm._e(),(item.contentType === 'Episode')?_c('div',{class:['episode-wrapper',
                       item.episodeNumber && 'episode-number-wrapper',
                       item.hasProtection
                         && _vm.getCurrentAccount.brushfireProtectionEnabled
                         && 'hasBrush'],attrs:{"title":item.episodeNumber ? ("Episode №" + (item.episodeNumber)) : 'Episode'}},[_c('inline-svg',{staticClass:"svg-icon",attrs:{"src":_vm.iconEpisode}}),(item.episodeNumber)?_c('div',{staticClass:"episode-number"},[_vm._v(" "+_vm._s(item.episodeNumber)+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"item-title",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)])}),0),(_vm.isInspector)?_c('div',{staticClass:"new-inspector"},[_c('Inspector',{key:_vm.mediaItem.id,attrs:{"mediaItem":_vm.mediaItem,"folderId":_vm.folderId,"getMediaList":_vm.getMediaList,"page":_vm.page,"mediaList":_vm.mediaList,"closeInspector":_vm.closeInspector,"openInspector":_vm.inspectMediaItem}})],1):_vm._e()]),(!_vm.mediaList.length && !_vm.isLoader)?_c('div',{staticClass:"empty-search"},[_vm._v(" No media to display ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }