<template>
  <div>
    <div class="cont">
      <div class="back">
      </div>
      <div
        class="progress"
        :style="{width: `${file.progress}%`}"
      >
      </div>
      <button
        class="btn"
        @click="onAbort"
      >
        <inline-svg
          :src="file.status === 'Uploaded' ? iconUploaded : iconRemove"
        >
        </inline-svg>
      </button>
    </div>
    <div
      class="status"
      :style="statusStyle"
    >
      {{ status }}
    </div>
  </div>
</template>

<script>
import {
  ABORT_UPLOADING_REQUEST,
  REMOVE_UPLOAD_REQUEST,
} from '../../store/actions/UploadingActions/upload';
import iconRemove from '../../assets/icons/icon-remove-upload.svg';
import iconUploaded from '../../assets/icons/icon-file-uploaded.svg';
import CONFIG from '../../constants/config';

export default {
  name: 'ProgressBar',
  props: {
    file: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      iconRemove,
      iconUploaded,
    };
  },
  computed: {
    statusStyle() {
      const {
        failedStatus,
        cancelStatus,
        successStatus,
        uploadedColor,
        failedColor,
      } = CONFIG.upload;
      if (this.file.status === cancelStatus || this.file.status === failedStatus) {
        return {
          color: failedColor,
        };
      }
      if (this.file.status === successStatus) {
        return {
          color: uploadedColor,
        };
      }

      return null;
    },
    disabled() {
      return !this.file.progress;
    },
    status() {
      if (this.file.status === 'Canceled') {
        return 'File canceled';
      }

      if (this.file.status === 'Uploaded') {
        return 'File uploaded';
      }

      return '';
    },
  },
  methods: {
    onAbort() {
      const {
        id,
        progress,
        status,
      } = this.file;
      if (progress !== CONFIG.upload.maxUploadPerCent && !status) {
        this.$store.dispatch(ABORT_UPLOADING_REQUEST, id);
      } else if (status) {
        this.$store.dispatch(REMOVE_UPLOAD_REQUEST, id);
      }
    },
  },
};
</script>

<style scoped>
  .cont {
    border-radius: 5px;
    height: 6px;
    position: relative;
    width: 350px;
  }

  .back {
    background-color: #43434d;
    border-radius: 5px;
    height: 100%;
  }

  .progress {
    background: repeating-linear-gradient(-45deg,
    rgba(197, 113, 47, 0.94),
    rgba(197, 113, 47, 0.94) 6px,
    rgb(197, 95, 32) 6px,
    rgb(197, 95, 32) 12px);
    border-radius: 5px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  .status {
    font-size: 13px;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .btn {
    position: absolute;
    right: -46px;
    top: -12px;
  }
</style>
